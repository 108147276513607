import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import install1 from '@/lib/install-1.png'
import install2 from '@/lib/install-2.png'
import install3 from '@/lib/install-3.png'
import install4 from '@/lib/install-4.png'
import install5 from '@/lib/install-5.png'

const ChromeInstall = () => {

    return (
        <>
            <div class="p-8">
                <div class="bg-white p-4 py-4 mt-12">
                    <h4 class="text-4xl font-bold text-gray-800 text-center">How to Install Chrome Extension ?</h4>
                    <div class="space-y-12 px-2 xl:px-16 mt-12">

                        <div className='flex flex-col'>
                            <div class="h-16">
                                <span class="text-lg text-black ">1. Download the latest (v0.1.2) extension packaged (It's free). After installation, you can upgrade to a Pro account directly in the software if you want.</span>
                            </div>
                            <div className='flex flex-row w-fit  gap-x-2 mt-4 items-center text-white font-semibold shadow-md text-base px-6 py-3 rounded-md border border-gray-200 bg-red-500 hover:bg-red-600'>
                                <a className='flex flex-row gap-x-2' href='https://fs.z-booster.com/download/map-leads-extractor(v0.1.2).zip'>
                                    <CloudDownloadIcon /> DOWNLOAD <div className='text-xs'>(v0.1.2)</div>
                                </a>
                            </div>
                        </div>

                        <div>
                            <div class="flex  h-16">
                                <span class="text-lg text-black ">2. After downloading extension packaged as a zip file, unzip the files into their own folder(Such as "~/Downloads/tmp/").</span>
                            </div>
                            <div class="flex items-center">
                                <img src={install1} alt='chrome install' />
                            </div>
                        </div>

                        <div>
                            <div class="flex h-16">
                                <span class="text-lg text-black ">3. In Chrome, navigate to chrome://extensions.</span>
                            </div>
                            <div class="flex items-center">
                                <img src={install2} alt='chrome install' />
                            </div>
                        </div>

                        <div>
                            <div class="flex h-16">
                                <span class="text-lg text-black ">4. Enable the Developer mode, and click the Load unpacked, then select the "~/Downloads/tmp/map-leads-extractor(v0.1.0)/" folder.</span>
                            </div>
                            <div class="flex items-center">
                                <img src={install3} alt='chrome install' />
                            </div>
                        </div>

                        <div>
                            <div class="flex h-16">
                                <span class="text-lg text-black ">5. Done!</span>
                            </div>
                            <div class="flex items-center">
                                <img src={install4} alt='chrome install' />
                            </div>
                        </div>

                        <div>
                            <div class="flex h-24">
                                <span class="text-lg text-black ">6. The extension is installed and you are ready to go. You can now manage the extension as you would any other, and its icon will appear in the Chrome address bar (if it has one) to the right of the address bar, pin it.</span>
                            </div>
                            <div class="flex items-center">
                                <img src={install5} alt='chrome install' />
                            </div>
                        </div>

                        <div>
                            We will always keep updating our products. If you have any questions, please feel free to contact us.
                            <p className="hover:underline">
                                <a href="mailto:support@z-booster.com">support@z-booster.com</a>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default ChromeInstall;