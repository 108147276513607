import ChromeSvg from "@/lib/chromeSvg";
import EdgeSvg from "@/lib/edgeSvg";
import banner from '@/lib/home-banner.jpg'
import banner1 from '@/lib/home-banner1.jpg'
import banner2 from '@/lib/home-banner2.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const VideoSlide = ({ url }) => {
    return (
        <ReactPlayer url={url} width="100%" playing={true} />
    )
}

const Home = () => {
    const navigate = useNavigate()

    const handleChromeInstall = () => {
        navigate('/chrome-install')
    }

    return (
        <>
            <div className="flex flex-col pt-14 pb-5 items-center">
                <p className="text-center font-semibold text-4xl ">
                    A powerful single-click tool to uncover commercial leads
                </p>
                <p className="flex flex-col md:flex-row  text-gray-600 font-light pt-3">
                    Automatically extracts key data from <p className="font-bold px-1">Google Maps</p> and outputs clean CSV files for seamless lead follow-up.
                </p>

                <div className="flex flex-col gap-y-6 md:flex-row gap-x-4 items-center pt-14">
                    <div onClick={handleChromeInstall} className='flex flex-row gap-x-1 items-center text-white font-semibold cursor-pointer shadow-md text-base px-4 py-3 rounded-md border border-gray-200 bg-red-500 hover:bg-red-600'>
                        <ChromeSvg />Free - Add to Chrome
                    </div>
                    <a target="_blank" rel="noopener noreferrer" href="https://microsoftedge.microsoft.com/addons/detail/cbmchdndmbmblmepmdlcjinbldhcgccb">
                        <div className='flex flex-row gap-x-1 items-center text-white font-semibold cursor-pointer shadow-md text-base px-7 py-3 rounded-md border border-gray-200 bg-red-500 hover:bg-red-600'>
                            <EdgeSvg />Free - Add to Edge
                        </div>
                    </a>
                </div>

                <div className="pt-24 w-full mx-auto">
                    <Carousel>
                        <div>
                            <img src={banner2} alt="banner" />
                        </div>
                        <div>
                            <img src={banner} alt='banner' />
                        </div>
                        <div>
                            <img src={banner1} alt='banner' />
                        </div>
                        <div>
                           <VideoSlide url={"https://youtu.be/fmmuUEvTRPs"} />
                        </div>
                    </Carousel>
                </div>

                <div className=" w-full mx-auto mt-12 bg-blue-50">
                    <div className=" flex flex-col break-words w-full mb-1 rounded">
                        <div className="w-full">

                            <table className="items-center w-full border-collapse table-fixed">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="px-6 border border-solid border-t-0 border-l-0 border-r-0 py-3 text-base uppercase whitespace-nowrap font-semibold text-left">
                                            Field Name
                                        </th>
                                        <th className="px-2 py-3 text-base font-semibold border border-t-0 uppercase border-solid border-l-0 border-r-0 whitespace-nowrap text-left">
                                            description
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Name
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Name of the place in Google Maps, this is usually the canonicalized business name.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Phone
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Phone numbers of this place.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Address
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            The human-readable address of this place.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Email
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            The emails found from the internet.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Price
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            The general consumption prices of this place.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Stars
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Star ratings of this place, in particular for hotels.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Rating
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            The place's rating, from 1.0 to 5.0, based on aggregated user reviews.
                                        </td>
                                    </tr>

                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            ReviewCount
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            The total number of reviews, with or without text, for this place.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            ReviewURL
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                        The review URL of place. A link for customers to leave reviews.
                                        </td>
                                    </tr>

                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            WebSite
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            The authoritative website for this place, such as a business homepage.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Photo
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            A photo for this place.
                                        </td>
                                    </tr>
                                    

                                    
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                        Latitude
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Latitude in decimal degrees of this place.
                                        </td>
                                    </tr>
                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                        Longitude
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Longitude in decimal degrees of this place.
                                        </td>
                                    </tr>

                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Categories
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            The categories for the Google My Business.
                                        </td>
                                    </tr>

                                    <tr className="border-solid border-b-[1px]">
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                        OpeningHours
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                        The opening hours of a place.
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="border-t-0 px-6 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Introduce
                                        </th>
                                        <td className="border-t-0 px-2 border-l-0 border-r-0 text-base font-normal whitespace-normal p-4 text-left">
                                            Feature introduction about this place.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Home;