import logo from '@/lib/logo16.png'
import { Outlet, useNavigate } from 'react-router-dom';

const Layout = () => {
    const navigate = useNavigate()

    const handleGoHome = ()=>{
        navigate('/')
    }

    return (
        <>
            <div className="flex flex-col h-screen">

                <header className="flex border-b-[1px] border-gray-200">
                    <div id='header-wrapper' className='flex flex-row px-6 md:px-0 py-4 max-w-screen-lg mx-auto w-full justify-between'>
                        <div onClick={handleGoHome}  className='flex flex-row gap-x-1 font-medium text-xl text-black cursor-pointer'>
                            <div className='w-8'>
                                <img src={logo} alt='logo' />
                            </div>
                            Map Leads Extractor
                        </div>
                        <div className='flex flex-row gap-x-5 font-medium cursor-pointer'>
                            <p><a href='/pricing'>Pricing</a></p>
                            <p><a href='/faq'>FAQ</a></p>
                        </div>
                    </div>
                </header>

                <div className="flex flex-col px-6 md:px-0 flex-grow max-w-screen-lg mx-auto w-full items-center">
                    <Outlet />
                </div>

                <div className='flex border-t-[1px] border-gray-200'>
                    <div id='booter-wrapper' className='flex flex-col md:flex-row justify-between max-w-screen-lg mx-auto w-full font-normal text-sm px-6 md:px-0 py-7'>
                        <div>
                            Copyright&nbsp;©&nbsp;2023 Z-Booster Ltd.
                        </div>
                        <div>
                            <a href='/terms'>Terms</a> | <a href='/privacy'>Privacy</a> | <a href='mailto:support@z-booster.com'>Contact</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Layout;